import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Link from "gatsby-link"
import Img from "gatsby-image"
import { motion } from "framer-motion"

import { useStaticQuery, graphql } from "gatsby"
import "font-awesome/css/font-awesome.min.css"
import "./Framing.css"

export default function Framing() {
  const settings = {
    dots: true,
    arrow: true,
    infinite: false,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const data = useStaticQuery(graphql`
    query {
      allFraming {
        nodes {
          id
          price
          title
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  let frameData = []
  data.allFraming &&
    data.allFraming.nodes.map(frame => {
      let imageSharp = frame.image.childImageSharp
      let fluid = imageSharp && imageSharp.fluid
      if (imageSharp && fluid) {
        let { id, title, price, description } = frame
        let d = {
          id,
          title,
          description,
          price,
          fluid,
        }
        frameData.push(d)
      }
    })

  console.log(frameData, "frameData ================")
  // frameData = []

  return (
    <div id="framing" className="framing-section">
      <div className="section-head col-sm-12">
        <h4>Frames</h4>
        <p>We have beautiful frames for you wedding photos.</p>
      </div>
      <div className="frame-list">
        <Slider {...settings}>
          {frameData &&
            frameData.map(frame => {
              let { id, title, price, fluid, description, tags } = frame
              return (
                <div
                  style={{
                    height: "100%",
                    textAlign: "center",
                    color: "black",
                  }}
                  className="frame-card"
                  key={id}
                >
                  <div>
                    <div className="frame-inner-card">
                      <div className="card-image">
                        <Img fluid={fluid} alt={title} />
                      </div>
                      <div className="content">
                        <div className="title">{title}</div>
                        <div className="pirce">Rs {price}</div>
                        <div>{description}</div>
                      </div>
                      <div className="tags">
                        {tags &&
                          tags.map(item => (
                            <div className="tag" key={item}>
                              {item}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </Slider>
      </div>
    </div>
  )
}

function SlideContent() {
  return (
    <div className="slide-content container">
      <h1 className="slide-header">Where the dream come true.</h1>
      <div className="slide-subText">
        A complete wedding photography and cinematography solution.
      </div>
    </div>
  )
}
