import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import "./About.css"

function About() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "about.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  `)
  return (
    <BackgroundImage
      fluid={data.file.childImageSharp.fluid}
      alt="Wedding Picture Background"
    >
      <div id="about" className="section-header">
        <div className="section-wrapper container">
          <h4 className="section-title">About Us</h4>
          <p>
            Affordable and Professional Nepali Wedding Photography and
            Cinematography Studio Thapas offers amazing discount wedding package
            deals to suit all budgets and requirements. Specialists in cinematic
            video, HD photography, excellent client service, affordable
            photography and video for all types of weddings.{" "}
          </p>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default About
