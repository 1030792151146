import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Image from "gatsby-image"
import "./Service.css"

export default function Service() {
  let url = process.env.REACT_APP_URL
  const data = useStaticQuery(graphql`
    query {
      photo: file(relativePath: { eq: "service/1.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
      video: file(relativePath: { eq: "service/2.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
      product: file(relativePath: { eq: "service/3.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
      design: file(relativePath: { eq: "service/4.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
      printing: file(relativePath: { eq: "service/5.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
      frame: file(relativePath: { eq: "service/6.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  `)

  console.log(data)
  return (
    <div id="service">
      <div className="container">
        <div className="row service-head">
          <div className="section-head col-sm-12">
            <h4>
              Why Choose <span>Us?</span>
            </h4>
            <p>
              We always on the lookout for moments of emotion. We feel like they
              tell the best story of your day. For some, that might mean
              enjoying a laugh together, for others it might mean wiping a tear
              from your loved one’s cheek. You’ve worked very hard planning for
              your “Big Day”. You almost want everything to go flawless. There
              will be so much happening that day. The last thing you should
              worry about if everything is being documented properly. That’s why
              we recommend you full day coverage package – one less thing for
              you to worry about. We will be with you from the very beginning of
              your day till the end. That means, you just enjoy to the fullest
              and look fabulous.
            </p>
          </div>
        </div>
        <div className="row service-row">
          <ServiceItem
            src={`${url}/images/service/1.jpg`}
            fluid={data.photo.childImageSharp.fluid}
            title="WEDDING PHOTOGRAPHY"
            text="Wedding photography isn’t about snapshots, it’s about the emotion that
            overtakes you when he locks eyes with you as you walk towards him down
            the aisle… the proud little tear that your father wipes away after he hands you
            over… it’s that shy giggle you’ll give during your first dance when, for a
            moment, you forget anyone else is in the room. Your wedding lasts merely
            one day, your wedding photos and movie will last a lifetime."
          />
          <ServiceItem
            src={`${url}/images/service/2.jpg`}
            fluid={data.video.childImageSharp.fluid}
            title="CINEMATIC WEDDING AND MUSIC VIDEOS"
            text="We have wide range of packages for all kind of weddings and
            Music videos.Please visit our video selection for samples."
          />
          <ServiceItem
            src={`${url}/images/service/4.jpg`}
            fluid={data.product.childImageSharp.fluid}
            title="PRODUCT AND COMMERCIAL PHOTOGRAPHY"
            text="Commercial and product photography for Ecommerce Websites, Retailers, Magazines, & Catalogs."
          />
          <ServiceItem
            src={`${url}/images/service/3.jpg`}
            fluid={data.design.childImageSharp.fluid}
            title="PORTRAIT PHOTOGRAPHY"
            text="Session fees include only the photographers time. Following your photoshoot you will have a review session where you will pick photos and products you would like to purchase."
          />
          <ServiceItem
            src={`${url}/images/service/5.jpg`}
            fluid={data.printing.childImageSharp.fluid}
            title="MATERNITY PHOTOGRAPHY"
            text="We also offer maternity Sessions. We will make sure that you will be comfortable."
          />
          <ServiceItem
            src={`${url}/images/service/6.jpg`}
            fluid={data.frame.childImageSharp.fluid}
            title="NEWBORN PHOTOGRAPHY"
            text="Want beautiful photos for your cute little baby ? We offer newborn photography session."
          />
          <ServiceItem
            src={`${url}/images/service/6.jpg`}
            fluid={data.frame.childImageSharp.fluid}
            title="Rice feeding"
            text="We provide the flex and frame print of the diffrent size and shape."
          />
          <ServiceItem
            src={`${url}/images/service/6.jpg`}
            fluid={data.frame.childImageSharp.fluid}
            title="Event Photography"
            text="We provide the flex and frame print of the diffrent size and shape."
          />
        </div>
      </div>
    </div>
  )
}

function ServiceItem({ src, title, text, fluid }) {
  return (
    <div className="col-12 p-0 service-item">
      <div className="item col-12">
        <div className="img-wrapper">
          {/* <img {...fluid} alt="wedding photograph" loading="lazy" /> */}
          <Image
            fluid={fluid}
            alt="weeding"
            imgStyle={{ objectFit: "cover" }}
          />
        </div>
        <div className="item-content col-12 col-md-6">
          <h6>{title}</h6>
          <p>{text}</p>
        </div>
      </div>
    </div>
  )
}
