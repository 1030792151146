import React from "react";
import "./Pricing.css";

function Pricing() {
  return (
    <div id="pricing">
      <div className="container">
      <div className=" section-head col-sm-12">
        <h4>Our Pricing</h4>
      </div>
      <div className="packages">
        <div className="package">
          <h3>Basic Package</h3>
          <div className="borderline"></div>
          <div className="p-list">
            <div>Photography</div>
            <div>HD Videography</div>
            <div>50 pics photo</div>
            <div>Full Event Coverage</div>
          </div>
        </div>

        <div className="package">
          <h3>Delux Package</h3>
          <div className="borderline"></div>
          <div className="p-list">
            <div>Photography</div>
            <div>HD Videography</div>
            <div>200 pics photo & Special Album</div>
            <div>Full Event Coverage</div>
            <div>Couple photoshoot</div>
            <div>Wedding highlights video.</div>
          </div>
        </div>

        <div className="package">
          <h3>Premium Package</h3>
          <div className="borderline"></div>
          <div className="p-list">
            <div>Photography</div>
            <div>HD Videography</div>
            <div>200 pics photo & Special Album</div>
            <div>Full Event Coverage</div>
            <div>Couple photoshoot</div>
            <div>Wedding highlights video.</div>
            <div>12x18 Photo Framing.</div>
            <div>Pre/Post wedding photoshoot</div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default Pricing;
