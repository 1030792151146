import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import PhotoGallery from "../PhotGallery/PhotoGallery"
import useWindowDimensions from "../Helper/useWidth"
import "./Photography.css"

export default function Photography() {
  const { height, width } = useWindowDimensions()
  let maxRows = width > 575 ? 3 : 3
  let rowHeight = Math.ceil(height / 2)
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "galleryHighlight" } }) {
        edges {
          node {
            childImageSharp {
              thumb: fluid(maxWidth: 350, maxHeight: 350) {
                ...GatsbyImageSharpFluid
              }
              full: fluid(maxWidth: 1800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  let photos =
    data.allFile &&
    data.allFile.edges.map(image => {
      let { full, thumb } = image.node.childImageSharp

      return {
        full,
        thumb,
        caption: "Studio Thapas",
      }
    })

  width > 575 ? (photos = photos.slice(0, 8)) : (photos = photos.slice(0, 9))

  // console.log(photos, "Photography", width)

  return (
    <div id="photo">
      <div className="section-head">
        <h4>Photography</h4>
        <p>
          Some of our latest photography. You can view more on our gallery section.
        </p>
      </div>
      <div style={{ marginTop: "5px", marginBottom: "20px" }}>
        {photos && (
          <PhotoGallery
            images={photos}
            maxRows={maxRows}
            rowHeight={rowHeight}
          />
        )}
      </div>
      <div className="view">
        <button className="btn-dark">
          <Link to="/photo" className="active">
            View More
          </Link>
        </button>
      </div>
    </div>
  )
}
