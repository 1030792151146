import React from "react"
import Img from "gatsby-image"
import Link from "gatsby-link"
import { motion } from "framer-motion"
import "./Blogs.css"
import Container from "components/ui/Container/Container"
import TitleSection from "components/ui/TitleSection/TitleSection"

function BlogUi({ posts, title, description }) {
  return (
    <div className="blogs-section">
      <Container section>
        {/* <TitleSection
          title={sectionTitle.title}
          subtitle={sectionTitle.subtitle}
          center
        /> */}
        <div className="section-head col-sm-12">
          <h4>{title}</h4>
          <p>{description}</p>
        </div>
        <div className="blogs-list">
          {posts.map(item => {
            const {
              id,
              fields: { slug },
              frontmatter: { title, cover, description, date, tags },
            } = item.node

            return (
              <div className="blogs-card" key={id}>
                <Link to={slug}>
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 1 }}
                  >
                    <div className="inner-card">
                      <div className="card-image">
                        <Img fluid={cover.childImageSharp.fluid} alt={title} />
                      </div>
                      <div className="content">
                        <div className="date">{date}</div>
                        <div className="title">{title}</div>
                        <div>{description}</div>
                      </div>
                      <div className="tags">
                        {tags.map(item => (
                          <div className="tag" key={item}>
                            {item}
                          </div>
                        ))}
                      </div>
                    </div>
                  </motion.div>
                </Link>
              </div>
            )
          })}
        </div>
      </Container>
    </div>
  )
}

export default BlogUi
