import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Link from "gatsby-link"
import Img from "gatsby-image"
import { motion } from "framer-motion"

import { useStaticQuery, graphql } from "gatsby"
import "font-awesome/css/font-awesome.min.css"
import "./Printing.css"

export default function Printing() {
  const settings = {
    dots: true,
    arrow: true,
    infinite: false,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const data = useStaticQuery(graphql`
    query {
      allPrinting {
        nodes {
          id
          price
          title
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  let printData = []
  data.allPrinting &&
    data.allPrinting.nodes.map(frame => {
      let imageSharp = frame.image.childImageSharp
      let fluid = imageSharp && imageSharp.fluid
      if (imageSharp && fluid) {
        let { id, title, price, description } = frame
        let d = {
          id,
          title,
          description,
          price,
          fluid,
        }
        printData.push(d)
      }
    })

  console.log(printData, "photoData ================")
  // printData = []

  return (
    <div id="printing" className="printing-section">
      <div className="section-head col-sm-12">
        <h4>printing</h4>
        <p>We provide the printing and creative design for you.</p>
      </div>
      <div className="print-list">
        <Slider {...settings}>
          {printData &&
            printData.map(frame => {
              let { id, title, price, fluid, description, tags } = frame
              return (
                <div
                  style={{
                    height: "100%",
                    textAlign: "center",
                    color: "black",
                  }}
                  className="print-card"
                  key={id}
                >
                  <div>
                    <div className="print-inner-card">
                      <div className="card-image">
                        <Img fluid={fluid} alt={title} />
                      </div>
                      <div className="content">
                        <div className="title">{title}</div>
                        <div className="pirce">Rs {price}</div>
                        <div>{description}</div>
                      </div>
                      <div className="tags">
                        {tags &&
                          tags.map(item => (
                            <div className="tag" key={item}>
                              {item}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </Slider>
      </div>
    </div>
  )
}

