import React from "react";
import "./Contact.css";

function Contact() {
  return (
    <div id="contact">
      <div className="contact__container">
        <div className="section-head col-sm-12">
          <h4>Get In Touch</h4>
          <p>
            Get in touch via contact form or email us directly. We will get back
            to you as soon as possible.
          </p>
        </div>

        <div className="contact__content">
          <form onSubmit={(e) => {
            e.preventDefault();
          }} className="contact__form clearfix">
            <div className="form-group">
              <label>Name</label>
              <input className="contact__form-input" type="text" />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input className="contact__form-input" type="text" />
            </div>
            <div className="form-group">
              <label>Meassage</label>
              <textarea className="contact__form-textarea" type="text" />
            </div>
            <div className="btn-section">
              <button type="submit" className="btn-dark contact-btn">Submit</button>
            </div>
          </form>
          <div className="contact__info">
            <div className="info__section">
              <h3>Where to Meet</h3>
              <div>Wedding Thapas</div>
              <div>Kadaghari, Kathmandu</div>
            </div>
            <div className="info__section">
              <h3>Say Hello</h3>
              <div>Email: weddingthapas@gmail.com</div>
              <div>
                Facebook:{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/Studiothapasphotography/"
                >
                  StudioThapas
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
