import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BlogUi from "./BlogUi";

const Posts = () => {
  const { markdownRemark, allMarkdownRemark } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "blog section" } }) {
        frontmatter {
          title
          subtitle
        }
      }
      allMarkdownRemark(
        filter: {
          frontmatter: { category: { eq: "blog" }, published: { eq: true } }
        }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            id
            html
            fields {
              slug
            }
            frontmatter {
              title
              description
              date(formatString: "MMM DD, YYYY")
              tags
              cover {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  // const sectionTitle = markdownRemark && markdownRemark.frontmatter
  const posts = allMarkdownRemark.edges
  return <BlogUi posts={posts} title="Blog" description="This are our lastest blog you can check out more in the blog section." />
}

export default Posts
