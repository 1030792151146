import React from "react"
import Showcase from "../components/Showcase/Showcase"
// import Testimonials from "../components/Testimonials/Testimonials";
import Youtube from "../components/Youtube/Youtube"
import Photography from "../components/Photography/Photography"
import Service from "../components/Service/Service"
import Contact from "../components/Contact/Contact"
import Footer from "../components/Footer/Footer"
import Navbar from "../components/Navbar/Navbar"
// import Slider from "../components/Slider/Slider";
import About from "../components/About/About"
import Framing from "../components/Framing/Framing"
import Blogs from "../components/Blogs/Blogs"
import Pricing from "../components/Pricing/Pricing"
import "../index.css"
import Printing from "../components/Printing/Printing"
import SEO from "../components/SEO/SEO";

function Home({ location }) {
  return (
    <>
      <div id="home">
        <SEO
          title="Home | Wedding Thapas"
          description="Affordable and Professional Nepali Wedding Photography and Cinematography Studio Thapas offers amazing discount wedding package deals to suit all budgets and requirements. Specialists in cinematic video, HD photography, excellent client service, affordable photography and video for all types of weddings."
        ></SEO>
        <Navbar location={location} />
        <Showcase />
        {/* <Testimonials /> */}
        <Service />
        <About />
        <Photography />
        <Youtube />
        <Pricing />
        <Blogs />
        <Printing />
        <Framing />
        <Contact />

        {/* <Slider /> */}
      </div>
      <Footer />
    </>
  )
}

export default Home
