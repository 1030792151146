import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import "font-awesome/css/font-awesome.min.css"
import "./Showcase.css"

let url = `${__dirname}src/assets`

const slideImages = [
  `${url}/images/cover-picture.jpg`,
  `${url}/images/cover1.jpg`,
  `../../assets/images/d/2.jpg`,
]

const fadeProperties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: false,
  indicators: true,
}

export default function Showcase() {
  const settings = {
    dots: true,
    fade: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 5000,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { absolutePath: { regex: "/cover/" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1000) {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div
      id="showcase"
      className="showcase"
      style={{ height: "calc(100vh - 40px)", marginTop: "-70px" }}
    >
      <Slider {...settings}>
        {data.allFile.edges.map(({ node }) => {
          return (
            <div key={node} style={{ height: "100%", textAlign: "center", color: "black" }} className="each-slide">
              <BackgroundImage
                fluid={node.childImageSharp.fluid}
                alt={"Cover Picture"}
              >
                {/* <div style={{ backgroundImage: `url(${slideImages[0]})` }}> */}
                <SlideContent />
                {/* Helloooooooooooo */}
                {/* </div> */}
              </BackgroundImage>
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

// export const query = graphql`
//   query {
//     file(relativePath: { eq: "images/heroImage.jpg" }) {
//       childImageSharp {
//         fluid(maxWidth: 1600, maxHeight: 800) {
//           ...GatsbyImageSharpFluid_withWebp
//         }
//       }
//     }
//   }
// `

function SlideContent() {
  return (
    <div className="slide-content container">
      {/* <div className="showcase-nav">
        <div className="contact">
          <div> 
            Kadaghari, Kathmandu </div>
          <div> 9841419161 </div>
          <div>studiothapas@gmail.com</div>
        </div>
        <div className="title">
          <div className="showcase-logo">Logo</div>
          <div className="showcase-name">Studio Thapas</div>
          <div className="showcase-sub">
            Wedding Photography And Cinematography
          </div>
        </div>
        <div className="link">
          <div className="link-item">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/Studiothapasphotography/"
            >
              <i class="fa fa-facebook-square"></i>
            </a>
          </div>
          <div className="link-item">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/studiothapas/"
            >
              <i class="fa fa-instagram"></i>
            </a>
          </div>
          <div className="link-item">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://za.pinterest.com/studiothapas/"
            >
              <i class="fa fa-pinterest-square"></i>
            </a>
          </div>
          <div className="link-item">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/studiothapas"
            >
              <i class="fa fa-twitter-square"></i>
            </a>
          </div>
        </div>
      </div>
       */}
      <h1 className="slide-header">Where the dream come true.</h1>
      <div className="slide-subText">
        A complete wedding photography and cinematography solution.
      </div>
    </div>
  )
}
